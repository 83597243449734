<template>
  <div>
    <b-modal
      id="apptWizModal"
      :visible="showBulkServiceRequestModal"
      size="lg"
      no-close-on-backdrop
      title="Service Request"
      :hide-footer="true"
      @hidden="onHidden"
    >
      <div class="text-right mb-2">
        <span class="required-legend">* Required</span>
      </div>
      <b-table
        striped
        no-border-collapse
        sticky-header="63vh"
        class="border"
        :items="unitsForServiceRequest"
        :fields="fields"
        :sort-by.sync="sortBy"
      >
        <template #head(location)>
          <div>
            Branch
            <sup>
              <font-awesome-icon
                id="branch_tooltip"
                icon="info-circle"
                size="lg"
                class="mx-1 text-primary"
                display="inline-block"
              />
            </sup>
            <b-tooltip target="branch_tooltip" triggers="hover">Service scheduled at this branch.</b-tooltip>
          </div>
        </template>
        <template #[`vin`]="{ item }">
          <label :class="`col-head`">VIN/Serial</label>
          <div>
            {{ item.vin }}
          </div>
        </template>
        <template #[`customer`]="{ item }">
          <div>{{ item }}</div>
        </template>
        <template #cell(actions)="{ item }">
          <b-button size="xsm" @click="removeUnit(item)">
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>

      <b-form-group class="pb-2" label-for="service-request-date">
        <date-time-input
          id="service-request-date"
          v-model="date"
          placeholder="MM/DD/YYYY"
          label="Service Date Requested:"
          label-class="col-head"
          format="MM/dd/yyyy"
          class="d-inline-block"
          typeable
          :disabled-dates="disabledDates"
          hide-time
          required
        />
      </b-form-group>

      <div class="mt-2 mb-2">
        <b-form-group label="Comments:" label-class="col-head" label-for="customerComments" class="pb-2">
          <div class="d-flex">
            <b-textarea
              id="customerComments"
              v-model="customerComments"
              class="overflow-auto rounded"
              size="sm"
              rows="1"
              max-rows="4"
            />
            <span class="required-placeholder"></span>
          </div>
        </b-form-group>
      </div>

      <b-button class="mt-2" variant="primary" :disabled="unitsAllRemoved || !date" type="submit" @click="submit">
        <b-spinner v-show="isBusy" small />
        {{ isBusy ? 'Requesting...' : 'Request Service' }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UserGetters } from '@/shared/store/user/types';
import { OperationsForecastGetters, OperationsForecastMutations } from '@/shared/store/operations-forecast/types';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import SchedulerService from '@/shared/services/SchedulerService';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import moment from 'moment';

export default {
  name: 'BulkServiceRequestModal',
  components: {
    'date-time-input': DateTimeInput
  },
  props: {
    standardOperationIds: {
      type: Array,
      default: null
    }
  },
  data: function () {
    return {
      fields: [
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'vin', label: 'VIN/Serial', sortable: true, collapsible: true },
        { key: 'location', label: 'Branch', sortable: true },
        { key: 'customer', label: 'Customer', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false, collapsible: true }
      ],
      unitsToExclude: [],
      date: null,
      comments: '',
      customerComments: null,
      isBusy: false,
      sortBy: ''
    };
  },
  computed: {
    ...mapGetters({
      user: UserGetters.GET_USER_PROFILE,
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      uniqueUnits: OperationsForecastGetters.GET_OPERATIONS_FORECAST_UNIQUE_UNITS,
      bulkServiceRequestModalFlag: OperationsForecastGetters.GET_BULK_SERVICE_REQUEST_MODAL_FLAG
    }),
    showBulkServiceRequestModal: {
      get() {
        return this.bulkServiceRequestModalFlag;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'showBulkServiceRequestModal', value });
      }
    },
    serviceRequestEnabled() {
      return this.isFeatureFlagEnabled('VcpServiceRequest') && this.$isCustomer;
    },
    unitsForServiceRequest() {
      return this.uniqueUnits.filter(
        unit => !this.unitsToExclude.some(unitToExclude => unitToExclude.unitId === unit.unitId)
      );
    },
    unitsAllRemoved() {
      return this.unitsForServiceRequest.length <= 0;
    },
    disabledDates() {
      return { to: this.minimumDate };
    },
    minimumDate() {
      return moment(this.dateCreate).startOf('day').toDate();
    }
  },
  methods: {
    ...mapMutations([OperationsForecastMutations.SET_PROP]),
    getMappedArrayOfUniqueBranchIds() {
      const uniqueAttributeMappedArrayOfObjects = [
        ...new Map(this.unitsForServiceRequest.map(item => [item.branchId, item])).values()
      ];

      const uniqueAttributeMappedArrayOfAttributesOnly = uniqueAttributeMappedArrayOfObjects.map(unit => unit.branchId);

      return uniqueAttributeMappedArrayOfAttributesOnly;
    },
    removeUnit: function (unitToRemove) {
      this.unitsToExclude.push(unitToRemove);
    },
    submit: async function () {
      try {
        this.isBusy = true;

        const uniqueBranchIds = this.getMappedArrayOfUniqueBranchIds();

        var that = this;
        var requestsByBranch = [];
        uniqueBranchIds.forEach(function (branchId) {
          var unitsForCurrentBranchOnly = that.unitsForServiceRequest
            .filter(unit => unit.branchId === branchId)
            .map(unit => {
              return {
                unitId: unit.unitId,
                standardOperationIds: null,
                serviceOrderId: null
              };
            });

          const request = {
            units: unitsForCurrentBranchOnly,
            complaint: 'Service Request for Multiple Units',
            branchId: branchId,
            contactEmail: that.user.email,
            contactName: that.user.name,
            customerComments: that.customerComments,
            serviceDateRequested: that.date
          };

          requestsByBranch.push(request);
        });
        await SchedulerService.requestService(requestsByBranch);

        this.onHidden();

        SuccessService.createSuccessToast(this.$root, `Service request sent.`);
      } catch (error) {
        ErrorService.createErrorToast(this.$root, `Error sending service request.`);
      } finally {
        this.isBusy = false;
      }
    },
    onHidden() {
      this.unitsToExclude = [];
      this.date = null;
      this.comments = '';
      this.customerComments = null;

      this.isBusy = false;

      this.showBulkServiceRequestModal = false;
    }
  }
};
</script>
